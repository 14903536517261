import React, { useEffect } from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import gsap from 'gsap'
import HeaderAbout from '../components/HeaderAbout'
import ClassicContent from '../components/ClassicContent'
import { Container, Row, Col } from 'react-grid-system'
import styled from 'styled-components'

const AboutWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const Link = styled.a`
  color: white;
  text-decoration: none;
  transition: color 0.4s ${props => props.theme.eases.out};

  &:hover {
    color: ${props => props.theme.colors.red};
  }
`

const About = () => {
  useEffect(() => {
    gsap.to(document.body, {
      backgroundColor: 'black',
      duration: 0.6,
      ease: 'power2.out'
    })

    return () => {
      gsap.to(document.body, {
        backgroundColor: 'white',
        duration: 0.6,
        ease: 'power2.out'
      })
    }
  })

  return(
    <Layout>
    <Seo title='About | Edwin Contat | Digital Art Director & Graphic Designer' />
    <AboutWrapper>
      <HeaderAbout/>
      <div>
        <Container fluid>
          <Row>
            <Col offset={{ sm: 1 }} sm={5} md={4} lg={3}>
              <ClassicContent
                pageTitle={true}
                number='01'
                title='About'
                content='I am Edwin Contat, a freelance art director specialized in graphic and interaction design, based in Lyon, France. Working with architecture, fashion, production, & cultural brands.'
              />
            </Col>
          </Row>
          <Row>
            <Col offset={{ xs: 1, sm: 6, lg: 7 }} xs={11} sm={4} md={3} lg={2}>
              <ClassicContent
                number='02'
                title='Services'
                content='Art Direction<br/>Website & E-commerce<br/>Branding<br/>Interaction / Motion design<br/>Graphic Design<br/>Photography direction'
              />
            </Col>
          </Row>
          <Row>
            <Col offset={{ sm: 2, lg: 3 }} sm={4} lg={3}>
              <ClassicContent
                number='03'
                title='Recognitions'
                content='<strong>Awwards</strong><br/>Site of the Day x1<br/>Honorable Mentions x6<br/>Mobile Excellence x5<br/>Mobile of the week x1<br/><br/><strong>FWA</strong><br/>FWA of the Day x1<br/><br/><strong>CSS Design Awards</strong><br/>Website of the Day x3<br/>Special Kudos x6'
              />
            </Col>
          </Row>
          <Row>
            <Col offset={{ xs: 1, sm: 8, lg: 9 }} xs={11} sm={4} lg={3}>
              <ClassicContent
                number='04'
                title='Contact'
                content={`<a href='mailto:hello@edwincontat.com'>hello@edwincontat.com</a>`}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ marginTop: 'auto', paddingBottom: '30px' }}>
        <Container fluid>
          <Row>
            <Col style={{ color: 'white', marginTop: '50px' }}>Dev by <Link href="https://lucasmesagouiffes.fr" rel='noopener' target='_blank'>LMG</Link></Col>
          </Row>
        </Container>
      </div>
    </AboutWrapper>
  </Layout>
  )
}

export default About
