import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'



const Header = styled.header`
  padding-top: 30px;

  ${up('sm')} { margin-bottom: 50px; }
`

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
`

const HeaderAbout = () => (
  <Header>
    <Container fluid>
      <Row justify='end'>
        <Col xs='content'><StyledLink to='/'>Back to home</StyledLink></Col>
      </Row>
    </Container>
  </Header>
)

export default HeaderAbout