import React, { useEffect, useRef, useContext } from 'react'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import Splitting from 'splitting'
import gsap from 'gsap'
import { LoaderContext } from '../context/loader';

const Wrapper = styled.div`
  margin: 30px 0;

  ${up('lg')} { margin: 0; }
`

const Content = styled.div`
  color: white;

  strong {
    font-weight: bold;
    text-transform: uppercase;
  }

  a {
    color: white;
    text-decoration: none;
    transition: color 0.4s ${props => props.theme.eases.out};

    &:hover {
      color: ${props => props.theme.colors.red};
    }
  }

  .word {
    display: inline-block;
    opacity: 0;
    transform: translateY(30px);
  }
`

const ClassicContent = (props) => {
  const title = useRef()
  const content = useRef()
  const lines = useRef()
  const hasLoaderEvent = useRef(false)
  const context = useContext(LoaderContext)

  useEffect(() => {
    lines.current = Splitting({ target: content.current, by: 'lines' })[0].lines
    if(!context.loading) setTimeout(appear, 200)
    else {
      hasLoaderEvent.current = true
      window.addEventListener('loader-end', appear)
    }

    return () => {
      if (hasLoaderEvent.current) window.removeEventListener('loader-end', appear)
    }
  })

  const appear = () => {
    gsap.to(title.current, {
      alpha: 1,
      y: 0,
      duration: 0.7,
      ease: 'power2.out'
    })

    lines.current.forEach((words, index) => {
      gsap.to(words, {
        delay: 0.075 * (index + 1),
        alpha: 1,
        y: 0,
        duration: 0.7,
        ease: 'power2.out'
      })
    })
  }

  return (
    <Wrapper>
      {props.pageTitle ?
        <h1 className='title' style={{ opacity: 0, transform: 'translateY(30px)'  }} ref={title}><span className='f12'>{props.number}.</span>{props.title}</h1> :
        <h2 className='title' style={{ opacity: 0, transform: 'translateY(30px)' }} ref={title}><span className='f12'>{props.number}.</span>{props.title}</h2>
      }
      <Content ref={content} dangerouslySetInnerHTML={{__html: props.content}}/>
    </Wrapper>
  )
}

export default ClassicContent